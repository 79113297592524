<template>
    <b-card>
        <div class="my-2">
            <b-row class="mx-2">
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Nombre</h4>
                    <h6 class="font-weight-normal" v-if="general.project != null">{{general.project.name}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Identificador</h4>
                    <h6 class="font-weight-normal">{{general.identifier}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Torre</h4>
                    <h6 class="font-weight-normal">{{general.tower != '' ? general.tower : "Sin datos"}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Lote</h4>
                    <h6 class="font-weight-normal">{{general.lots != '' ? general.lots : "Sin datos"}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <!-- Si la bodega va en conjunto con el estacionamiento -->
                    <h4 class="font-weight-bolder">Tipo</h4>
                    <h6 class="font-weight-normal">{{general.type != '' ? general.type : "Sin datos"}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Disponiblidad</h4>
                    <h6 class="font-weight-normal">{{general.available ? "Si" : "No"}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Área total</h4>
                    <h6 class="font-weight-normal">{{general.total_area > 0 ? general.total_area : 0}} mts<sup>2</sup></h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Área municipal</h4>
                    <h6 class="font-weight-normal">{{general.municipal_area > 0 ? general.municipal_area : 0}} mts<sup>2</sup></h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Área terraza</h4>
                    <h6 class="font-weight-normal">{{general.terrace_area > 0 ? general.terrace_area : 0}} mts<sup>2</sup></h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Área construcción</h4>
                    <h6 class="font-weight-normal">{{general.built_area > 0 ? general.built_area : 0}} mts<sup>2</sup></h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Piso</h4>
                    <h6 class="font-weight-normal">{{general.floor}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Precio</h4>
                    <h6 class="font-weight-normal">{{numberPrice(general.price)}}</h6>
                </b-col>
            </b-row>
        </div>
        <div>
            <hr/>
        </div>
        <div class="mx-3 my-3">
            <b-row>
                <b-col>
                    <div class="align-center flex">
                        <h2 class="font-weight-bolder"><feather-icon
                        icon="BellIcon"
                        size="30"
                        class="mr-50"/>Estado</h2>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="my-2">
            <b-row class="mx-2">
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Visible</h4>
                    <b-form-checkbox v-model="general.visible" switch size="lg"></b-form-checkbox>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Se puede reservar</h4>
                    <b-form-checkbox v-model="general.bookeable" switch size="lg"></b-form-checkbox>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Se puede promesar</h4>
                    <b-form-checkbox v-model="general.promisable" switch size="lg"></b-form-checkbox>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import {mapActions} from 'vuex'
import ToastificationContent from "@core/components/toastification/ToastificationContent"
    export default {
        name: 'WarehouseDetailGeneral',
        props: ['general'],
         watch: {
            'general.visible': function (val) {
                this._changeWarehouseVisible();
            },
            'general.bookeable': function (val) {
                this._changeWarehouseBookable();
            },
            'general.promisable': function (val) {
                this._changeWarehousePromisable();
            },
        },
        methods:{
            ...mapActions('properties',['changeWarehouseVisible','changeWarehouseBookable','changeWarehousePromisable']),
            numberPrice(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            async _changeWarehouseVisible () {
                await this.changeWarehouseVisible(this.general)
                .then(res => {
                    this.showSuccess();
                }).catch(err => {
                    this.showError();
                }).finally(() => {
                })
            },

            async _changeWarehouseBookable () {
                await this.changeWarehouseBookable(this.general)
                .then(res => {
                    this.showSuccess();
                }).catch(err => {
                    this.showError();
                }).finally(() => {
                })
            },

            async _changeWarehousePromisable () {
                await this.changeWarehousePromisable(this.general)
                .then(res => {
                    this.showSuccess();
                }).catch(err => {
                    this.showError();
                }).finally(() => {
                })
            },

            showSuccess:function(){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Guardado con exito',
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                    },
                })
            },
            showError:function(){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Existe un error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                })
            }
        }
    }
</script>