<template>
    <div id="propeties-details">
        <warehouses-details-list :warehouse="warehouse"></warehouses-details-list>
    </div>
</template>

<script>
    import WarehousesDetailsList from './components/WarehousesDetailsList'

    export default{
        name: 'WarehousesDetails',
        components: {WarehousesDetailsList},
        props: ['warehouse'],
        data() {
            return {

            }
        },
    }
</script>