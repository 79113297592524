<template>
    <div>
        <b-tabs pills vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-class="nav-left"
        nav-wrapper-class="col-md-3 col-12">
            <b-tab active>
                <template #title>
                <feather-icon
                    icon="UserIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">General</span>
                </template>
                <warehouse-detail-general :general="warehouse"></warehouse-detail-general>
            </b-tab>
            <div v-if="warehouse.complements.length > 0">
                <b-tab v-if="warehouse.complements[0].warehouse != null">
                    <template #title>
                    <feather-icon
                        icon="BoxIcon"
                        size="18"
                        class="mr-50"/>
                    <span class="font-weight-bold">Bodega</span>
                    </template>
                    <warehouse-detail-warehouse :warehouse="warehouse.complements[0].warehouse"></warehouse-detail-warehouse>
                </b-tab>
                <b-tab v-if="warehouse.complements[0].parkinglot != null">
                    <template #title>
                    <feather-icon
                        icon="TruckIcon"
                        size="18"
                        class="mr-50"/>
                    <span class="font-weight-bold">Estacionamiento</span>
                    </template>
                    <warehouse-detail-parkinglot :parkinglot="warehouse.complements[0].parkinglot"></warehouse-detail-parkinglot>
                </b-tab>
            </div>
        </b-tabs>
    </div>
</template>

<script>
    import WarehouseDetailGeneral from './details/warehouse/WarehouseDetailGeneral'
    import WarehouseDetailParkinglot from './details/warehouse/WarehouseDetailParkinglot'
    import WarehouseDetailWarehouse from './details/warehouse/WarehouseDetailWarehouse'

    export default{
        name: 'WarehousesDetailsList',
        props: ['warehouse'],
        components:{
            WarehouseDetailGeneral,
            WarehouseDetailParkinglot,
            WarehouseDetailWarehouse
        },
        data() {
            return {
            }
        },
    }
</script>